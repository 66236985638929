import getData from "@/__main__/get-data.mjs";
import eventBus from "@/app/app-event-bus.mjs";
import noopModel from "@/data-models/no-op.mjs";
import ValorantPlayerMatchWinHistoryModel from "@/data-models/valorant-player-match-win-history.mjs";
import * as API from "@/game-val/api.mjs";
import fetchConstants from "@/game-val/fetch-constants-data.mjs";
import { getExpiryTime } from "@/game-val/utils.mjs";
import fetchProfile, {
  getAuthorizationHeaders,
} from "@/game-val/valorant-fetch-profile.mjs";
import { EVENT_VAL_LOADING_SCREEN } from "@/routes/constants.mjs";
import { devLog } from "@/util/dev.mjs";

export default async function fetchData(_, __, state) {
  const startLoadingTs = Date.now();

  const [content] = await fetchConstants();

  const promises = [];

  const headers = await getAuthorizationHeaders();
  const latestActId = content.acts?.find((act) => act.endedAt === null)?.uuid;

  const shouldShadow = API.shouldShadow();

  const teamProfiles =
    state.players?.map(({ gameName, tagLine }) =>
      fetchProfile(gameName, tagLine)
        .then((profile) => {
          if (shouldShadow) {
            getData(
              API.getMatchWinHistoryV2({
                puuid: profile.puuid,
                actUuid: latestActId,
                queue: state.queue,
                offset: 0,
                first: 5,
              }),
              noopModel,
              undefined,
              { headers },
            );
          }
          return Promise.all([
            getData(
              API.getMatchWinHistory({
                puuid: profile.puuid,
                actUuid: latestActId,
                queue: state.queue,
                offset: 0,
                first: 5,
              }),
              ValorantPlayerMatchWinHistoryModel,
              [
                "val",
                "playerMatchWinHistory",
                profile.puuid,
                latestActId,
                state.queue,
              ],
              { headers, expiryTime: getExpiryTime() },
            ),
          ]);
        })
        .catch(devLog),
    ) ?? [];
  promises.push(...teamProfiles);

  eventBus.emit(EVENT_VAL_LOADING_SCREEN, {
    duration: Date.now() - startLoadingTs,
  });
  return Promise.all(promises);
}
